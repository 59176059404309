import invariant from 'invariant';
import { ComponentProps, ReactElement, cloneElement } from 'react';

import { Box } from 'components/Box';
import { AnchorButton } from 'components/Button/AnchorButton';
import {
  CmsApiCmsPageLink,
  CmsApiLinkType,
  CmsApiUrlLink,
} from 'modules/cms/api/types/CmsApiLink';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { isExternalURL } from 'utils/url/isExternalURL';

type Props = {
  buttonData: CmsApiUrlLink | CmsApiCmsPageLink;
  eventProperties: Record<string, unknown>;
  children: ReactElement<ComponentProps<typeof AnchorButton>>;
};

export function CmsLinkButton(props: Props) {
  const { eventProperties, buttonData, children } = props;

  const { url } = buttonData.data;
  invariant(url, 'Button url is not defined');

  const linkRel = isExternalURL(url) ? `noopener noreferrer}` : undefined;
  const linkTarget = isExternalURL(url) ? '_blank' : undefined;

  return cloneElement(
    children,
    {
      onClick: () => {
        trackClicked('CMS Block', {
          url,
          ...eventProperties,
        });
      },
      href: url,
      title:
        buttonData.type === CmsApiLinkType.CMS_PAGE
          ? buttonData.data.pageTitle
          : undefined,
      target: linkTarget,
      rel: linkRel,
      'data-qa-id': 'cms-button-link',
    },
    <Box display="flex" alignItems="center">
      {buttonData.text}
    </Box>,
  );
}
